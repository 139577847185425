import { defineStore } from 'pinia';
import type { CustomerDto } from '@containex/portal-backend-dto';

interface CustomerState {
    customers: CustomerDto[] | null;
    totalCustomerAmount: number;
}

export const useCustomerStore = defineStore('customer', {
    state: (): CustomerState => ({
        customers: null,
        totalCustomerAmount: 0,
    }),
});

import { computed, type ComputedRef } from 'vue';
import { useCustomerStore } from '@/stores/customer';
import { type CustomerDto, PaginationStep, type UpdateCustomerRequestDto } from '@containex/portal-backend-dto';
import { useAuthenticationStore } from '@/stores/authentication';
import { companyCustomersApi } from '@containex/portal-backend-api-client';
import useGlobalToast from '@/composables/useGlobalToast';
import { httpClient } from '@/common/api/http-client';

export interface CustomerQuery {
    customers: ComputedRef<CustomerDto[] | null>;
    totalCustomerAmount: ComputedRef<number>;
}

export interface CustomerAction {
    fetchCustomers(customerAmount: number, step: PaginationStep): Promise<void>;

    updateCustomer(customerId: string, data: UpdateCustomerRequestDto): Promise<void>;
}

export function useCustomerQuery(): CustomerQuery {
    const store = useCustomerStore();

    return {
        customers: computed(() => store.customers),
        totalCustomerAmount: computed(() => store.totalCustomerAmount),
    };
}

export function useCustomerAction(): CustomerAction {
    const customerStore = useCustomerStore();
    const authenticationStore = useAuthenticationStore();

    return {
        async fetchCustomers(customerAmount: number, step: PaginationStep): Promise<void> {
            try {
                const customers = customerStore.customers;
                let pageCursorId;

                if (step === PaginationStep.New || customers == null) {
                    pageCursorId = null;
                } else {
                    const filterCustomer =
                        step === PaginationStep.Next ? customers[customers.length - 1] : customers[0];
                    pageCursorId = filterCustomer?.id;
                }

                const response = await companyCustomersApi.fetchCustomers(httpClient, {
                    customerAmount: String(customerAmount),
                    step,
                    pageCursorId,
                });

                if (response.data != null) {
                    customerStore.customers = response.data.customers;
                    customerStore.totalCustomerAmount = response.data.totalCustomerAmount;
                }
            } catch {
                const { errorToastOptions, addToast } = useGlobalToast();

                customerStore.customers = [];

                addToast(errorToastOptions);
            }
        },
        async updateCustomer(customerId: string, data: UpdateCustomerRequestDto): Promise<void> {
            const response = await companyCustomersApi.updateCustomer(httpClient, { customerId }, data);

            authenticationStore.currentCustomer = response.data;
        },
    };
}
